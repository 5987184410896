import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import t from "../theme/theme";

const HamburgerMenu = styled.button`
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  display: inline-block;
  width: ${t.spacing(4)};
  margin: ${t.spacing(2.5)} auto;

  @media (min-width: ${t.desktop}) {
    display: none;
  }
  span {
    display: block;
    width: 100%;
    height: 3px;
    margin-bottom: 5px;
    background-color: ${t.blue};
    transition: all 0.1s ease-in-out;
    &:first-child {
      transform: ${(props) => (props.menuActive ? `rotate(-135deg)` : `none`)};
    }

    &:nth-child(2) {
      transform: ${(props) =>
        props.menuActive ? `rotate(135deg) translate(-5px, 5px)` : `none`};
    }

    &:nth-child(3) {
      opacity: ${(props) => (props.menuActive ? 0 : 1)};
    }
  }

  &:focus {
    outline: none;
  }
`;

const StyledLink = styled(Link)`
  color: ${t.blue};
  font-size: ${t.spacing(3.5)};
  text-orientation: upright;
  writing-mode: vertical-lr;
  padding-top: ${t.spacing(4)};
  line-height: 1.6;
`;

const StyledHead = styled.header`
  color: ${t.blue};
  display: flex;
  flex-direction: column;
  height: 100vh;

  @media (min-width: ${t.desktop}) {
    padding-left: ${t.spacing(2)};
  }
`;

const Header = (props) => {
  const handleClick = () => {
    props.onMenuClick();
  };
  return (
    <StyledHead>
      <StyledLink to={`/`}>Suet Yi Chan</StyledLink>
      <HamburgerMenu
        onClick={handleClick}
        menuActive={props.menuActive}
        title={`Navigation`}
      >
        <span />
        <span />
        <span />
      </HamburgerMenu>
    </StyledHead>
  );
};

export default Header;
