const t = {
  poemsBg: `rgba(199, 234, 251, .97)`,
  red: `#ed1c24`,
  blue: `#4E7282`,
  text: `#333333`,
  desktop: `990px`,
  headerWidth: `45px`,
  navWidthDesktop: `180px`,
  poemsBarWidthMobile: `30px`,
  poemsBarWidthDesktop: `100px`,
  spacing: function (multiplier) {
    return `${multiplier * 8}px`;
  },
};

export default t;
