import React, { useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import styled from "styled-components";
import t from "../theme/theme";

const StyledNav = styled.nav`
  position: absolute;
  width: calc(100vw - ${t.headerWidth});
  height: 100vh;
  opacity: ${(props) => (props.menuActive ? 1 : 0)};
  top: ${(props) => (props.menuActive ? `0` : `-100vh`)};
  right: 0;
  background-color: ${t.blue}e5;
  transition: all 0.3s ease-in-out;
  z-index: 9;
  color: white;
  padding-left: ${t.spacing(3)};

  @media (min-width: ${t.desktop}) {
    position: relative;
    width: auto;
    top: auto;
    background-color: transparent;
    color: ${t.blue};
    opacity: 1;
    padding-top: 15vh;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    z-index: 0;
  }
`;

const StyledMenu = styled.ul`
  font-size: ${t.spacing(3)};
  &,
  & ul {
    list-style: none;
    padding-inline-start: 0;
  }

  ul {
    padding-left: ${t.spacing(1.5)};
  }

  @media (min-width: ${t.desktop}) {
    margin-top: 0;
    font-size: ${t.spacing(2)};
    line-height: 1.6;
  }
`;

const StyledMenuItem = styled.li`
  margin-bottom: ${t.spacing(1.5)};
`;

const NavigationBar = (props) => {
  const [showWork, setShowWork] = useState(false);
  const data = useStaticQuery(graphql`
    query pagesQuery {
      allFile(filter: { sourceInstanceName: { eq: "work" } }) {
        edges {
          node {
            name
            childMarkdownRemark {
              frontmatter {
                title
                order
              }
            }
          }
        }
      }
    }
  `);
  const works = data.allFile.edges
    .sort(
      (a, b) =>
        a.node.childMarkdownRemark.frontmatter.order -
        b.node.childMarkdownRemark.frontmatter.order
    )
    .map((work) => {
      return {
        name: work.node.childMarkdownRemark.frontmatter.title,
        to: work.node.name,
      };
    });
  const navItems = [
    {
      name: "Work",
      to: "#",
      children: works,
      parent: "work",
    },
    {
      name: "Words",
      to: "text",
    },
    {
      name: "News",
      to: "news",
    },
    {
      name: "CV",
      to: "cv",
    },
    {
      name: "Contact",
      to: "contact",
    },
    {
      name: "Shop",
      to: "shop",
    },
  ];
  return (
    <StyledNav menuActive={props.menuActive} className={props.className}>
      <StyledMenu>
        {navItems.map((navItem) => (
          <StyledMenuItem key={navItem.name}>
            {!navItem.children && (
              <Link to={`/${navItem.to}`}>{navItem.name}</Link>
            )}
            {!!navItem.children && (
              <div>
                <Link
                  to={`/${navItem.to}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowWork(!showWork);
                  }}
                >
                  {navItem.name}
                </Link>
                {showWork && (
                  <ul>
                    {navItem.children.map((child) => (
                      <li key={child.to}>
                        <Link to={`/${navItem.parent}/${child.to}`}>
                          {child.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </StyledNav>
  );
};

export default NavigationBar;
