import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import ReactMarkdown from "react-markdown";
import Slider from "react-slick";
import Arrow from "../../static/images/arrow.svg";
import Close from "../../static/images/close.svg";
import styled from "styled-components";
import t from "../theme/theme";

const sliderSettings = {
  dots: true,
  vertical: true,
  fade: true,
  verticalSwiping: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const StyledPoems = styled.aside`
  background-color: ${t.poemsBg};
  position: relative;
`;

const TogglesContainer = styled.div`
  text-orientation: upright;
  writing-mode: vertical-lr;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  @media (min-width: ${t.desktop}) {
    text-orientation: initial;
    writing-mode: initial;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: ${t.desktop}) {
    flex-direction: column;
    width: ${t.spacing(10)};
    margin-top: ${t.spacing(2)};
  }
`;

const ToggleBtnIcon = styled.img`
  display: block;
  width: ${t.spacing(4)};
  margin-bottom: ${t.spacing(1)};
`;

const ToggleBtnIconArrow = styled(ToggleBtnIcon)`
  transform: rotate(270deg);
`;

const StyledSlider = styled(Slider)`
  opacity: ${(props) => (props.active ? `1` : `0`)};
  transition: opacity 0.2s ease-in-out;
  flex: 1;
  display: flex;
  align-items: center;

  .slick-slide {
    min-height: 50vh;
    font-family: "dolly", serif;
    font-style: italic;
    padding: 0 1.5em;
  }

  .slick-prev,
  .slick-next {
    position: fixed;
    top: auto;
    bottom: ${t.spacing(3)};
    left: auto;
    right: auto;
    transform: none;
    width: auto;
    height: auto;
    font-size: ${t.spacing(2)};
    color: ${t.text};
    display: flex !important;
    align-items: center;
    &:before {
      display: flex;
      color: ${t.text};
      content: "";
      opacity: 1;
      background: url(${Arrow});
      width: ${t.spacing(5)};
      height: ${t.spacing(5)};
      transform: rotate(270deg);
      margin: 0 ${t.spacing(1)};
      @media (min-width: ${t.desktop}) {
        transform: none;
        margin-bottom: ${t.spacing(2)};
      }
    }
    @media (min-width: ${t.desktop}) {
      left: 45%;
      flex-direction: column;
    }
  }

  .slick-prev {
    @media (min-width: ${t.desktop}) {
      top: 100px;
      bottom: auto;
    }
  }

  .slick-next {
    right: 0;

    &:before {
      order: 1;
      transform: rotate(90deg);
      @media (min-width: ${t.desktop}) {
        transform: rotate(180deg);
        margin-top: ${t.spacing(2)};
      }
    }
    @media (min-width: ${t.desktop}) {
      bottom: ${t.spacing(2)};
    }
  }

  .slick-list {
    @media (min-width: ${t.desktop}) {
      position: absolute;
      max-width: 40%;
      left: 30%;
      top: 30vh;
    }
  }

  .slick-dots {
    display: none;
    @media (min-width: ${t.desktop}) {
      position: fixed;
      top: 10vh;
      right: 5vw;
      bottom: auto;
      width: auto;
      display: flex !important;
      flex-direction: column;

      li {
        margin: ${t.spacing(2)} ${t.spacing(1)};
        &.slick-active {
          text-decoration: underline;
        }
        button {
          font-size: ${t.spacing(2)};
          color: ${t.blue};
          &:before {
            display: none;
          }
        }
      }
    }
  }
`;

const Poems = ({ poemsActive, location, togglePoems, ...props }) => {
  const data = useStaticQuery(
    graphql`
      query PoemsQuery {
        allFile(
          filter: { sourceInstanceName: { eq: "poems" } }
          sort: { fields: childMarkdownRemark___frontmatter___order }
        ) {
          edges {
            node {
              sourceInstanceName
              childMarkdownRemark {
                frontmatter {
                  title
                  content
                }
              }
            }
          }
        }
      }
    `
  );
  return (
    <StyledPoems className={props.className}>
      <TogglesContainer onClick={togglePoems}>
        {poemsActive && (
          <ToggleContainer>
            <ToggleBtnIcon src={Close} alt="" />
            <div>Close</div>
          </ToggleContainer>
        )}
        {!poemsActive && (
          <ToggleContainer>
            <ToggleBtnIconArrow src={Arrow} alt="" />
            <div>Poems</div>
          </ToggleContainer>
        )}
      </TogglesContainer>
      <StyledSlider active={poemsActive} {...sliderSettings}>
        {data.allFile.edges.map((item) => {
          const poem = item.node.childMarkdownRemark.frontmatter;
          return (
            <div key={poem.title}>
              <ReactMarkdown children={poem.content} />
            </div>
          );
        })}
      </StyledSlider>
    </StyledPoems>
  );
};

export default Poems;
