import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Poems from "./poems";
import NavigationBar from "./navigation";
import Header from "./header";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { GlobalStyle } from "../theme/styles";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import favicon from "../../static/images/favicon.ico";
import t from "../theme/theme";

const StyledMain = styled.main`
  display: grid;
  grid-template-columns: ${t.headerWidth} 1fr ${t.poemsBarWidthMobile};
  grid-template-rows: 1fr;
  max-height: 100vh;
  overflow: hidden;

  @media (min-width: ${t.desktop}) {
    grid-template-columns: calc(${t.headerWidth} + ${t.spacing(2)}) ${t.navWidthDesktop} 1fr ${t.poemsBarWidthDesktop};
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledHeader = styled(Header)`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
`;

const StyledSection = styled.section`
  overflow: auto;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;

  @media (min-width: ${t.desktop}) {
    grid-column-start: 3;
    grid-column-end: 4;
  }
`;

const StyledPoems = styled(Poems)`
  position: fixed;
  left: ${(props) =>
    props.poemsActive
      ? `${t.headerWidth}`
      : `calc(100vw - ${t.poemsBarWidthMobile})`};
  width: calc(100vw - ${t.headerWidth});
  height: 100vh;
  z-index: 2;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  @media (min-width: ${t.desktop}) {
    left: ${(props) =>
      props.poemsActive
        ? `calc(${t.headerWidth} + ${t.spacing(2)})`
        : `calc(100vw - ${t.poemsBarWidthDesktop})`};
  }
`;

const StyledFooter = styled.footer`
  position: fixed;
  padding: ${t.spacing(0.5)};
  width: 100%;
  left: 0;
  bottom: 0;
  font-size: ${t.spacing(1.25)};
`;

const Layout = ({ location, title, ...props }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  title = !!title ? title : site.siteMetadata.title;
  const [menuActive, setMenuActive] = useState(false);
  const [poemsActive, setPoemActive] = useState(false);
  const toggleMenu = () => setMenuActive(!menuActive);
  const togglePoems = () => setPoemActive(!poemsActive);
  useEffect(() => {
    setPoemActive(location.pathname === "/");
  }, []);
  return (
    <StyledMain>
      <Helmet title={title} titleTemplate={`%s | ${site.siteMetadata.title}`}>
        <link rel="icon" href={favicon} type="image/pnng" />
      </Helmet>
      <GlobalStyle />
      <StyledHeader onMenuClick={toggleMenu} menuActive={menuActive} />
      <NavigationBar menuActive={menuActive} />
      <StyledSection>{props.children}</StyledSection>
      <StyledPoems
        poemsActive={poemsActive}
        location={location}
        togglePoems={togglePoems}
      />
      <StyledFooter>©{new Date().getFullYear()} Suet Yi Chan</StyledFooter>
    </StyledMain>
  );
};

export default Layout;
