import styled, { createGlobalStyle } from "styled-components";
import NeuzeitBookWoff from "../../static/fonts/NeuzeitSLTStd-Book.woff";
import NeuzeitBookTtf from "../../static/fonts/NeuzeitSLTStd-Book.ttf";
import NeuzeitBookHeavyWoff from "../../static/fonts/NeuzeitSLTStd-BookHeavy.woff";
import NeuzeitBookHeavyTtf from "../../static/fonts/NeuzeitSLTStd-BookHeavy.ttf";
import DollyWoff from "../../static/fonts/36575D_2_0.woff";
import DollyTtf from "../../static/fonts/36575D_2_0.ttf";
import DollyItalicWoff from "../../static/fonts/36575D_0_0.woff";
import DollyItalicTtf from "../../static/fonts/36575D_0_0.ttf";
import DollyBoldWoff from "../../static/fonts/36575D_1_0.woff";
import DollyBoldTtf from "../../static/fonts/36575D_1_0.ttf";
import DollyScWoff from "../../static/fonts/36575D_3_0.woff";
import DollyScTtf from "../../static/fonts/36575D_3_0.ttf";
import t from "./theme";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'neuzeit';
    src: url(${NeuzeitBookWoff}) format('woff'),
    url(${NeuzeitBookTtf}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'neuzeit';
    src: url(${NeuzeitBookHeavyWoff}) format('woff'),
    url(${NeuzeitBookHeavyTtf}) format('truetype');
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'dolly';
    src: url(${DollyWoff}) format('woff'),
    url(${DollyTtf}) format('truetype');
  }

  @font-face {
    font-family: 'dolly';
    src: url(${DollyItalicWoff}) format('woff'),
    url(${DollyItalicTtf}) format('truetype');
    font-style: italic;
  }

  @font-face {
    font-family: 'dolly';
    src: url(${DollyBoldWoff}) format('woff'),
    url(${DollyBoldTtf}) format('truetype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'dollysc';
    src: url(${DollyScWoff}) format('woff'),
    url(${DollyScTtf}) format('truetype');
  }
  
  body {
    font-family: "neuzeit","Helvetica Neue",Helvetica,Arial,sans-serif;
    color: ${t.text};
    margin: 0;
    padding: 0;
    * {
      box-sizing: border-box;
    }
  }
  
  a {
    text-decoration: none;
    &, &:visited {
      color: inherit;
    }
  }
  
  .slick-slide {
    div {
      &:focus {
        outline: none;
      }
    }
  }
  
  p {
    img {
      max-width: 100%;
    }
  }
`;

export const StyledH1 = styled.h1`
  writing-mode: vertical-lr;
  text-orientation: upright;
  color: ${t.red};
  text-transform: uppercase;
  font-size: ${t.spacing(2.5)};
  letter-spacing: 0.3em;
  font-weight: 500;
  padding: 15vh ${t.spacing(1)} ${t.spacing(1)};
  margin: 0;
`;

export const StyledSectionWrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const ContentWrapperInner = styled.div``;

export const StyledWorkImg = styled.img`
  max-width: 60vw;
  max-height: 70vh;
`;
